











import { Component, Mixins } from 'vue-property-decorator';
import { ListingFilter, ListingOrderBy } from 'client-website-ts-library/filters';
import { ListingStatus, ListingCategory } from 'client-website-ts-library/types';
import { View } from 'client-website-ts-library/plugins';

import Listings from '../components/Listings.vue';

@Component({
  components: {
    Listings,
  },
})
export default class PropertiesSold extends Mixins(View) {
  private listingFilter: ListingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Leased,
    ],
    Categories: [
      ListingCategory.ResidentialSale,
      ListingCategory.ResidentialLand,
      ListingCategory.ResidentialRental,
      ListingCategory.Rural,
      ListingCategory.Commercial,
      ListingCategory.CommercialLand,
    ],
    PageSize: 9,
  });
}
